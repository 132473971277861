.container {
  width: 100%;
  padding: 4px 8px;
}

.content {
  display: grid;
  grid-template-columns: [first] 20px [second] auto [end];
  align-items: center;
  gap: 15px;
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.213px;
  height: 36px;
  padding: 6px 6px 6px 8px;
}

.content .thumbnail {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #ffb4c9;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #e6f1ff;
  text-align: center;
  font-family: Sora;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.content:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
}

.selected {
  background: #e6f1ff;
  border-radius: 4px;
}