.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container:hover {
  cursor: pointer;
}

.thumbnail {
  background-size: contain;
  flex: 1;
  position: relative;
  border-radius: 8px 8px 0 0;
}

.menuIcon {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 15px;
  height: 15px;
  background: #848199;
  -webkit-mask: url(/images/menu-icon.svg) no-repeat center;
  mask: url(/images/menu-icon.svg) no-repeat center;
  mask-size: contain;
}

.menuIcon:hover {
  background: var(--blue);
}

.boardName {
  position: absolute;
  left: 10%;
  width: 80%;
  top: 50%;
  transform: translate(0, -50%);
}

.details {
  border-top: 1px solid #e6e9ef;
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
}

.details.editing {
  background-color: #f6f9fc;
}

.lastModified {
  font-family: Poppins, Roboto, Arial;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #797e93;
  margin: 0;
}

.canvasMenu {
  background-color: #fff;
  border-radius: 6px;
  padding: 8px 6px 8px 6px;
  display: flex;
  flex-direction: column;

  font-family: Poppins, Roboto, Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  white-space: nowrap;
  text-align: initial;
}

.separator {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  border: 0.86px solid #ebedf3;
}
