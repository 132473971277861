.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.startContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 8px;
}

.centerContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  gap: 20px;
}

.endContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.teamPicker {
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  gap: 4px;
}

.teamPicker:hover {
  cursor: pointer;
}

.teamTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  padding-bottom: 20px;
}

.teamPickerSkeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.teamTitleSekeleton {
  padding-bottom: 20px;
  height: 22px;
}

.teamIcon {
  width: 22px;
  height: 22px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.teamName {
  color: #113357;
  font-family: "Sora";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.646px;
  letter-spacing: 0.122px;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 45vh;
  padding: 10px 0;
}

.projectsTitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  padding: 10px 0;
}

.projectsTitle > span {
  color: #11355e;
  font-family: "Sora";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.152px;
}

.plusIcon {
  color: #11355e;
}

.plusIcon:hover {
  cursor: pointer;
  color: #1973ff;
}

.menuOption {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #11355e;
  width: 100%;
  padding: 10px 4px;
  display: flex;
  gap: 6px;
}

.menuOption img {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.menuOption .teammatesIcon {
  width: 12px;
  height: 12px;
}

.projectsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  padding: 10px 0;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.projectsList::-webkit-scrollbar {
  display: none;
}

.teammatesTitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  padding: 20px 0;
}

.teammatesTitle > span {
  color: #11355e;
  font-family: "Sora";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.152px;
}

.selectedProject {
  background: #e6f1ff;
}

.projectItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menuIcon {
  width: 15px;
  height: 15px;
  background: #848199;
  -webkit-mask: url(/images/menu-icon.svg) no-repeat center;
  mask: url(/images/menu-icon.svg) no-repeat center;
  mask-size: contain;
}

.projectName {
  all: unset;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #11355e;
  width: 100%;
  padding: 10px 4px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #dadce0;
  align-self: center;
}

.teamPickerMenuDivider {
  width: 100%;
  height: 1px;
  background: #ebedf3;
  align-self: center;
}

.teammates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.teammatesSkeleton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 186px;
  margin-top: 15px;
}

.teammatesCount {
  color: #848199;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.152px;
}

.teammatesCount:hover {
  cursor: pointer;
}

.teamPickerMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 200px;
  background: #fff;
  margin-left: 12px;
  align-items: flex-start;
  z-index: 100;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  padding: 10px 8px;
  max-height: 300px;
  overflow-y: auto;
}

.projectList {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  overflow-y: auto;
  flex: 1;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: calc(45vh - 40px);
}

.projectList:hover {
  scrollbar-width: thin;
  -ms-overflow-style: auto;
}

.team {
  width: 184px;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;
  margin-left: 8px;
  padding-left: 4px;
}

.team:hover {
  cursor: pointer;
  background: #e6f1ff;
}

.name {
  color: #11355e;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.152px;
}

.selectTeam {
  background: #f4f7fa;
}

.arrow {
  background: #ffffff;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  right: 6px;
  top: -6px;
}

.teamPickerMenuButton {
  width: 184px;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  margin: 4px 0 4px 8px;
  gap: 8px;
}

.teamPickerMenuButton:hover {
  cursor: pointer;
  background: #e6f1ff;
}

.createNewTeam {
  color: #1973ff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.132px;
}

.teamsCanvases {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #11355e;
  width: 100%;
  padding: 8px 10px;
  display: flex;
  gap: 10px;
  margin: 12px 0;
}

.teamsCanvases:hover {
  cursor: pointer;
}

.templateLibrary {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #11355e;
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
  padding: 8px 10px;
}

.templateLibrary:hover {
  cursor: pointer;
  background-color: #e6f1ff;
}

.selected {
  background-color: #e6f1ff;
  color: #11355e;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.152px;
}

.manageTeam {
  color: #1973ff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.152px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 6px;
  margin-top: 5px;
}

.manageTeam:hover {
  cursor: pointer;
}

.more {
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.152px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 6px;
  margin-top: 5px;
  width: 100%;
}

.more:hover {
  cursor: pointer;
  background-color: #e6f1ff;
}

.teammateSkeleton {
  width: 100%;
  height: 22px;
  margin-bottom: -1rem;
}

.teammatesPreview {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #11355e;
  width: 100%;
  display: flex;
  gap: 6px;
}

.teammatesPreview img {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.teammatesPreview .teammatesIcon {
  width: 12px;
  height: 12px;
}

.innerContainer {
  max-width: 155px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}
