.noCanvases {
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-line;
  gap: 14px;
  padding: 90px 20px;
}

.noCanvases img {
  width: 114px;
  height: 96px;
}

.noCanvases > span {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 150% */
  letter-spacing: 0.213px;
}
