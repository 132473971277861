.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 30px;
  margin: 0px 37px;
  padding: 24px 22px 14px 22px;
  background: #f7f9fb;
  border-radius: 6px;
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
}

.titleLine {
  color: #113357;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.213px;
}

.subtitleLine {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.213px;
  margin-bottom: -3px;
}

@media screen and (max-width: 620px) {
  .subtitleLine {
    display: none;
  }
}

.templateContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 22px;
  transform: translateZ(1px);
}

@media (max-width: 730px) {
  .recommendedTemplates {
    display: none;
  }
}

.grid {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  transition: width 0.5s ease-in-out;
}

.unlockCard {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 8px rgba(11, 38, 66, 0.15);
  height: 118px;
  width: 346px;
  padding: 18px;
  cursor: pointer;
}

.unlockContainer {
  display: flex;
}

.unlockButton {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  height: 32px;
  border-radius: 2px;
  background: var(--blue);
}

.unlockButton:hover {
  cursor: pointer;
  background: var(--dark-blue);
}

.unlockButton span {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

@media (max-width: 900px) {
  .titleContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}
