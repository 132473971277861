.container {
    width: 100%;
    padding: 4px 8px;
}

.content {
    display: flex;
    align-items: center;
    gap: 6px;
    max-width: inherit;
    color: #113357;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.213px;
    padding: 6px;
}

.thumbnail {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #FFB4C9;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: #e6f1ff;
    text-align: center;
    font-family: Sora;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.accountMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    border: 1px solid #ebedf3;
    border-radius: 6px;
    background: #fff;
    padding: 10px;
}

.item {
    width: 100%;
    padding: 6px;
    color: #11355e;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 300;
    line-height: 25.612px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.item:hover {
    cursor: pointer;
    background: #e6f1ff;
    border-radius: 4px;
}

.accountName {
    width: 100%;
    padding: 6px;
    color: #11355e;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 300;
    line-height: 25.612px;
    display: flex;
    align-items: center;
    gap: 10px;
}