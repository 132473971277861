.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;
  gap: 13px;
}

.emptyStateText {
  color: #AFB3BF;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.createProjectText {
  color: #1973FF;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.124px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.createProjectText:hover {
  cursor: pointer;
  color: #113357;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.dotTyping {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.dotTyping::before,
.dotTyping::after {
  content: '';
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #AFB3BF;
  margin: 0 2px;
  animation: dotTyping 1s infinite ease-in-out;
}

.dotTyping::before {
  animation-delay: 200ms;
}

.dotTyping::after {
  animation-delay: 400ms;
}

@keyframes dotTyping {
  0% {
    transform: scale(0.3);
    opacity: 0.3;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.3);
    opacity: 0.3;
  }
}

.cell {
  padding: 4px 10px;
  align-items: baseline;
  margin-bottom: 4px;
  min-height: 35px;
}