.container {
  width: 517px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  border-radius: 16px;
}

.footerContainer {
  background: #fff;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 25px;
  background: #fff;
  border-radius: 16px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.permissionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 0 25px;
  background: #fff;
}

.permissionDropDown {
  width: 103px;
  height: 32px;
  fill: #fff;
  border: 1px solid #dadce0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.permissionDropDown:hover {
  cursor: pointer;
}

.permissionDropDown > span {
  color: #113357;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.219px;
}

.allCollaborators {
  height: 302px;
  overflow-y: auto;
  padding-top: 25px;
}

.collaborators {
  padding: 0 17px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.selectedCollaborators {
  padding: 0 17px 4px 17px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 4px;
}

.inviteField {
  background: #f8fafd;
  padding: 16px 0;
}

.inviteField > span {
  padding-left: 16px;
  color: #113357;
  font-family: "Sora";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.fieldContainer {
  min-height: 32px;
  margin-top: -8px;
  border-radius: 6px;
}

.link {
  color: #1973ff;
  white-space: nowrap;
  text-align: right;
  font-size: 12px;
  font-family: Poppins;
  line-height: 13.165px;
  letter-spacing: 0.219px;
}

.link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.disabled {
  color: #848199;
  cursor: none;
  opacity: 0.5;
}

.disabled:hover {
  cursor: none;
}

.divider {
  width: 100%;
  height: 1px;
  background: #dadce0;
  align-self: center;
}

.title {
  color: #113357;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.124px;
  padding: 25px 25px 0;
}

.arrow {
  background: #ffffff;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  left: 90px;
  top: -6px;
  border-left: 1px solid #ebedf3;
  border-top: 1px solid #ebedf3;
}

.closeIcon {
  cursor: pointer;
  width: 14px;
  height: 14px;
  align-self: flex-end;
  position: absolute;
  top: 12px;
  right: 12px;
  color: #848199;
}

.closeIcon:hover {
  color: #c2c3c9;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.manageSeats {
  color: #1973ff;
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.165px;
  letter-spacing: 0.219px;
  text-decoration-line: underline;
  align-self: flex-end;
  padding-right: 25px;
}

.manageSeats:hover {
  cursor: pointer;
  opacity: 0.5;
}

.subtitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 25px;
  padding-top: 20px;
}

.subtitle {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.213px;
}

.dynamicErrorMessage {
  color: #f8746b;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.219px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 17px;
}
