.container {
  background-color: white;
  transition: all 100ms;
  max-width: 224px;
  max-height: 200px;
  min-height: 184px;
  border-radius: 6px;
  outline: 2px solid #dee1eb;
  transition: outline 0.2s, transform 0.2s ease-in-out;
  position: relative;
}

.container:hover {
  filter: none;
  transform: scale(1.02);
  outline: 3px solid #b3d0fe;
}