.initialsIcon {
  width: 22px;
  height: 22px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.213px;
}
