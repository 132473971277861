.list {
  padding: 10px 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.cell {
  position: relative;
  padding: 0 10px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 34px;
}

.cell:hover {
  background-color: #efefef;
  cursor: pointer;
  border-radius: 4px;
}

.selectedProject:hover {
  background: #e6f1ff;
  cursor: pointer;
  border-radius: 4px;
}

.index {
  font: 300 14px "Poppins";
  width: 10px;
}

.menuIcon {
  color: #afb3bf;
  padding: 0 11px;
  border-radius: 6px;
}

.menuIcon:hover {
  color: #113357;
  cursor: pointer;
  background-color: #e0e1e1;
}

.selectedIcon:hover {
  background-color: #c3ddff;
}

.hiddenFrameText {
  height: 20px;
  display: flex;
  gap: 8px;
  align-content: center;
  padding: 4px;
}

.hiddenFrameText span {
  font-family: Poppins, Roboto, Arial;
  text-align: center;
  color: #797e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.framesEmptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 42px;
  height: 82%;
  gap: 20px;
}

.framesEmptyState img {
  width: 110px;
}

.framesEmptyState p {
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

.selectedProject {
  background: #e6f1ff;
  border-radius: 4px;
}

.projectMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;
  border: 1px solid #ebedf3;
  background: #fff;
  padding: 10px;
  border-radius: 6px;
}

.item {
  width: 100%;
  padding: 6px;
  color: #11355e;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 25.612px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.item:hover {
  cursor: pointer;
  background: #e6f1ff;
  border-radius: 4px;
}

.delete {
  color: #ff6a5f;
}

.arrow {
  background: #ffffff;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  left: 65px;
  top: -6px;
  border-left: 1px solid #ebedf3;
  border-top: 1px solid #ebedf3;
}

.rename {
  background-color: #fff;
  border: 1px solid #1973ff;
}

.projectName {
  display: flex;
  align-items: center;
  gap: 8px;
}