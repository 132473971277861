.container {
  width: 659px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: stretch;
  padding: 25px;
  position: relative;
  border-radius: 16px;
}

.title {
  color: #113357;
  text-align: left;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 29.809px;
  letter-spacing: -0.098px;
}

.subtitle {
  color: #0b2642;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.213px;
}

.note {
  color: #848199;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.213px;
}

.closeIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  align-self: flex-end;
}

.picker {
  display: flex;
  gap: 5px;
}

.picker > * {
  flex-basis: 50%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.optionRenderer {
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.toastText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  width: fit-content;
}

.errorTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #fd461e;
  width: fit-content;
}

.icon {
  color: #fd461e;
  display: flex;
}

.errorContent {
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.myCanvasesIcon {
  border-radius: 50%;
  background-color: #F05C30;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
}
