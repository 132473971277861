.container {
  width: 100%;
  padding: 20px 13px 20px 17px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #113357;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.213px;
  margin-bottom: 20px;
}

.newTeam {
  margin-right: -3px;
  padding: 0 4px;
  border-radius: 6px;
}

.newTeam:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.teamsContainer {
  display: flex;
  flex-direction: column;
  margin-left: -6px;
  gap: 2px;
}

.teamHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px;
  margin: 2px 0;
  height: 34px;
}

.selectedTeam {
  background: #e6f1ff;
  border-radius: 4px;
}

.teamHeader:hover {
  background: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
}

.selectedTeam:hover {
  background: #e6f1ff;
  cursor: pointer;
  border-radius: 4px;
}

.teamToggle {
  color: #113357;
  font-size: 10px;
  width: 12px;
  height: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.teamName {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.213px;
}

.createTeamSection {
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;
}

.createTeamButton {
  display: flex;
  align-items: center;
  color: #3b82f6;
}

.createTeamButton:hover {
  color: #2563eb;
}

.createTeamIcon {
  margin-right: 0.25rem;
}

.allTeams {
  display: flex;
  align-items: center;
  gap: 12px;
}